import React from "react";

const SocialShare = [
 
   {
     iconName: "facebook",
     link: "https://www.facebook.com/Champputtipongg",
   },
  { iconName: "line", link: "https://line.me/ti/p/stKYTwhF6m" },

  {
    iconName: "linkedin",
    link: "https://www.linkedin.com/in/puttipong-c/",
  },
  {iconName: "youtube",
  link: "https://www.youtube.com/channel/UCAL0kuqfrdasnduRf3FOAkA",
    
  }
];
const Social = () => {
  return (
    <>
      <ul className="social">
        {SocialShare.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/assets/img/svg/social/${val.iconName}.svg`}
                alt="social"
              ></img>
            </a>
          </li>
        ))}
      </ul>
      {/* END SOCIAL */}
    </>
  );
};

export default Social;
