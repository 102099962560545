import React, { useState } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image ">
          <img src="assets/img/slider/home_profile.png" alt="about" />
        </div>
        {/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">Puttipong Charoenyingsathaporn (Champ)</h3>
          <div className="description_inner">
            
             
              
              {/* <div className="tokyo_tm_button">
                <button onClick={toggleModal} className="ib-button">
                  Read More
                </button>
              </div> */}
              {/* END TOKYO BUTTON */}
            
            {/* END LEFT */}
            <div className="right">
            
              <ul>
                {/* <li><p><span>Timeline</span></p></li> */}
                <li>
                <p>
              I’m a <b>technologist</b> with expertise in <b>marketing, data, and business.</b>
              
              </p>
                </li>
                <li>
                <br></br>
                  <p>
                  "<b>My daily habits</b> are reading an hour a day, exercising for 30 minutes, meditating and learning from others every day".
                  </p>
                </li>

                <li>
                  <p>
                  <br></br>
                    <span>2022</span><a href="https://predictive.co.th/" target="_blank" id="anyone_link">Predictive</a> gave me the opportunity to be an Assistant Data Analytics Manager.
Helped lead and contribute with a great team in the full loop of Data Collection & Data Activation process for leading organizations including fast food restaurant chain, banking and E-Commerce industries under the PDPA law that has go live in  this year.
                  </p>
                </li>
           
                <li>
                  <p>
                  <br></br>
                    <span>2021</span>As Product Manager, I have been part of the Quality team at <a href="https://www.vonder.co.th/" target="_blank" id="anyone_link">Vonder</a> and have Led the creation of EdTech products used by more than 20,000 users in 5 leading organizations including hospitality and banking industries.
                  </p>
                </li>

                <li>
                  <p>
                  <br></br>
                    <span>2020</span>After always kept learning knowledge in Marketing Technology at <a href="https://www.g-able.com/" target="_blank" id="anyone_link">G-Able</a> for 1 year, I involved in team development with new team members coming in and done various training, as well as being a leader in finding various tools / technics to use within the great team.
                  </p>
                </li>

                <li>
                  <p>
                  <br></br>
                    <span>2019</span><a href="https://www.g-able.com/" target="_blank" id="anyone_link">G-Able</a> gave me the opportunity to work as the first Digital Technologist of the Digital Marketing team, gave me the space to start learning from 0.
                  </p>
                </li>

                <li>
                  <p>
                  <br></br>
                    <span>2018</span><a href="https://www.uppercuz.com/" target="_blank" id="anyone_link">Uppercuz creative</a> gave me the opportunity to start working as Project Coordinator role while I was in my senior year of college.
                  </p>
                </li>

                <li>
                  <p>
                  <br></br>
                    <span>2017</span>During my time at <a href="https://www.sit.kmutt.ac.th/" target="_blank" id="anyone_link">School of Information Technology at KMUTT</a> I had the opportunity to be the Camp president of Ways to IT Professionals Camp #9, Led and managed the camp that have 100+ staffs and 100 students who are interested in entrancing the faculty and enhanced the development of people in the team in each position.
                  </p>
                </li>


              </ul>
              {/* END UL */}
            </div>
            {/* END RIGHT */} 
          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>

      {/* START ABOUT POPUP BOX */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_about">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END POPUP CLOSE BUTTON */}
          <div className="box-inner">
            <div
              className="description_wrap scrollable"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="my_box">
                <div className="left">
                  <div className="about_title">
                    <h3>Photography Skills</h3>
                  </div>
                  {/* END ABOUT TITLE */}

                  <div className="tokyo_progress">
                    <div className="progress_inner" data-value="95">
                      <span>
                        <span className="label">Wedding Photography</span>
                        <span className="number">95%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 95 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_inner" data-value="80">
                      <span>
                        <span className="label">Lifestyle Photography</span>
                        <span className="number">80%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 80 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_inner" data-value="90">
                      <span>
                        <span className="label">Family Photography</span>
                        <span className="number">90%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 90 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END PROGRESS */}
                </div>
                {/* END LEFT */}

                <div className="right">
                  <div className="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  {/* END TITLE */}
                  <div className="tokyo_progress">
                    <div className="progress_inner" data-value="95">
                      <span>
                        <span className="label">English</span>
                        <span className="number">95%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 95 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_inner" data-value="90">
                      <span>
                        <span className="label">Japanese</span>
                        <span className="number">90%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 90 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className="progress_inner" data-value="85">
                      <span>
                        <span className="label">Arabian</span>
                        <span className="number">85%</span>
                      </span>
                      <div className="background">
                        <div className="bar">
                          <div
                            className="bar_in"
                            style={{ width: 85 + "%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* EDN TOKYO PROGRESS */}
                </div>
                {/* END RIGHT */}
              </div>
              {/* END MYBOX */}

              <div className="counter">
                <div className="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div className="list_inner">
                      <h3>777+</h3>
                      <span>Projects Completed</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>3K</h3>
                      <span>Happy Clients</span>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>9K+</h3>
                      <span>Lines of Code</span>
                    </div>
                  </li>
                </ul>
                {/* END COUNTER CONTENT */}
              </div>
              {/* END COUNTER */}

              <div className="partners">
                <div className="about_title">
                  <h3>Our Partners</h3>
                </div>
                <Brand />
              </div>
              {/* END PARTNER SLIDER */}
            </div>
          </div>
        </div>
      </Modal>
      {/* END ABOUT POPUP BOX */}
    </>
  );
};

export default About;
